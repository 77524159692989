<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg
        .selectAll(`.kline,.k_bar,.line,.dk_text,.dk_line,.text,.x_tick,.y_tick,.tick_text`)
        .transition()
        .duration(100)
        .style("opacity", "0");
      await sleep(150);
      $("#" + this.id).html("");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    async KLineChart(
      data,
      {
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xPadding = 0.3,
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长        
        totalLength = 60, //默认60根柱子
        lineColors = ["#FF1CA4", "#fcaa05", "#178CEA"], //均线颜色
      } = {}
    ) {
      const X = data.map((d) => d.label);
      const Y = data.map((d) => d.k);
      const dataLen = data.length;

      xDomain = X;

      const yMin = d3.min(Y, (d) => {
        return d[2];
      });
      const yMax = d3.max(Y, (d) => {
        return d[3];
      });

      const theSameGap = [yMax - yMin] * 0.18;
      yDomain = [yMin - theSameGap, yMax + theSameGap];

      if (dataLen < totalLength) {
        xRange = [marginLeft, ((width - marginRight - marginLeft) * dataLen) / totalLength + marginLeft];
      }
      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);


      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;


      const yTick = [1, 2, 3, 4];
    //   const xTick = [1, 2, 3, 4, 5, 6, 7];
      
      const xTick = Array.from(Array(totalLength+1).keys()).slice(1);
      const yTickSpace = (height - marginTop - marginBottom) / (yTick.length - 1);
      const xTickSpace = (width - marginLeft - marginRight) / (xTick.length - 1);

      const rowLine = svg
        .append("g")
        .attr("class", "y_tick_group")
        .selectAll("line")
        .data(yTick)
        .enter()
        .append("line")
        .attr("x1", (d, i) => marginLeft)
        .attr("y1", (d, i) => marginTop + yTickSpace * i)
        .attr("x2", (d, i) => marginLeft)
        .attr("y2", (d, i) => marginTop + yTickSpace * i)
        .attr("class", (d, i) => {
            return "y_tick"+i
        })
        .attr("stroke", "#F0F0F0")
        .attr("stroke-width", 2)
        .attr("stroke-dasharray", 5)
        .attr("opacity", 0)
        .transition()
        .ease(d3.easeCubicOut)
        .delay((d, i) => i * 50)
        .duration(300)
        .attr("x2", (d, i) => width - marginLeft- marginRight);

      const columnLine = svg
        .append("g")
        .attr("class", "x_tick_group")
        .selectAll("line")
        .data(xTick)
        .enter()
        .append("line")
        .attr("x1", (d, i) => marginLeft + xTickSpace * i)
        .attr("y1", (d, i) => marginTop)
        .attr("x2", (d, i) => marginLeft + xTickSpace * i)
        .attr("y2", (d, i) => marginTop)
        .attr("class", (d, i) => {
            return "x_tick"+i
        })
        .attr("stroke", "#F0F0F0") //F0F0F0
        .attr("stroke-width", 2)
        .attr("stroke-dasharray", 5)
        .attr("opacity",  (d, i) => {
            if(i == Math.ceil(totalLength*3/4-1)){
                return 1
            }else {
                return 0
            }
            })
        .transition()
        .ease(d3.easeCubicOut)
        .delay((d, i) => i * 5)
        .duration(300)
        .attr("y2", (d, i) => height - marginBottom);



      const drawXY = () => {
        const yAxis = d3.axisRight(yScale).tickSize(0).ticks(4);
      // y轴
      const axisY = svg
        .append("g")
        .attr("class", "axis_y_right")
        .attr("transform", `translate(${width - marginRight},0)`)
        .call(yAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line")
            .clone()
            .attr("x2", -(width - marginLeft - marginRight))
            .attr("stroke", "#C5C5C5")
            .attr("class", "tick_long_line")
            .attr("opacity", 0);
        });
        axisY.selectAll(".tick_long_line").transition().duration(duration).attr("opacity", 1);
      }
      drawXY()
      //画x轴日期
      // const axisX = [];
      // if (dataLen < 15) {
      //   axisX.push(X[0]);
      // } else if (dataLen >= 15 && dataLen <= 30) {
      //   axisX.push(X[0]);
      //   axisX.push(X[X.length - 1]);
      // } else if (dataLen > 30) {
      //   axisX.push(X[0]);
      //   axisX.push(X[Math.floor(X.length / 2)]);
      //   axisX.push(X[X.length - 1]);
      // }
      const axisX = [];
      if (dataLen < 15) {
        axisX.push(X[0]);
      } else if (dataLen >= 15) {
        axisX.push(X[0]);
        axisX.push(X[X.length - 1]);
      }
      const xText = svg
        .append("g")
        .attr("class", "x_tick_text")
        .selectAll("text")
        .data(axisX)
        .enter()
        .append("text")
        .attr("x", (d, i) => {
          return i == axisX.length - 1 && axisX.length > 1
            ? xScale(d) + xScale.bandwidth()
            : xScale(d);
        })
        .attr("y", () => height - marginBottom)
        .attr("class", "tick_text")
        .text((d) => {
          d = String(d)
          if(d.indexOf(" ")>-1){
            return d
          }else {
            return `${d.slice(0,4)}-${d.slice(4, 6)}-${d.slice(6, 8)}`
          }

        })
        .attr("text-anchor", (d, i) => {
          if (i == 0) {
            return "start";
          } else if (i == axisX.length - 1) {
            return "end";
          } else {
            return "middle";
          }
        })
        .attr("dy", "1.1em")        
        .attr("fill", "#FFF")
        .attr("opacity", "0")
        .transition()
        .duration(200)
        .delay((d, i) => i * delay * Math.floor(X.length / 2))
        .attr("opacity", "1");

      //画y轴最大最小值
      svg
        .append("g")
        .attr("class", "y_tick_text")
        .selectAll("text")
        .data(yDomain)
        .enter()
        .append("text")
        .attr("class", (d, i) => (i == 0 ? "min_text text" : "max_text text"))
        .attr("x", (d) => marginLeft + 5)
        .attr("y", (d, i) => {
          if (i == 0) {
            return height - marginBottom;
          } else {
            return marginTop;
          }
        })
        .text((d) => d.toFixed(2))
        .attr("text-anchor", "start")
        .attr("dy", (d, i) => {
          if (i == 0) {
            return "-0.2em";
          } else {
            return "1em";
          }
        })
        .attr("fill", (d, i) => (i == 0 ? "#FF3B30" : "#18AA0C"))
        .attr("opacity", "0")
        .transition()
        .duration(200)
        .attr("opacity", "1");

      var lines = svg
        .append("g")
        .attr("class", "k_lines_group")
        .selectAll("line")
        .data(X)
        .enter()
        .append("line")
        .attr("x1", (d, i) => xScale(d) + xScale.bandwidth() / 2) //open close low high
        .attr("x2", (d, i) => xScale(d) + xScale.bandwidth() / 2)
        .attr("y1", (d, i) => yScale(Y[i][0] / 2 + Y[i][1] / 2))
        .attr("y2", (d, i) => yScale(Y[i][0] / 2 + Y[i][1] / 2)) //线从开盘收盘的中点向上下两端生长
        .attr("class", (d, i) =>
          Y[i][0] <= Y[i][1] ? "kline kline_positive" : "kline kline_negative"
        )
        .attr("stroke", "currentColor");

      lines
        .transition()
        .delay((d, i) => i * delay)
        .duration(200)
        .attr("y1", (d, i) => yScale(Y[i][2]))
        .attr("y2", (d, i) => yScale(Y[i][3]));


      //标注多空信号
      const dkArr = [];
      data.forEach((item, index) => {
        if (item.iBs == 2 || item.iBs == 1) {
          dkArr.push(index);
        }
      });
      const dkGroup = svg.append("g").attr("class", "dk_group");
      const dkLine = dkGroup
        .selectAll("line")
        .data(dkArr)
        .enter()
        .append("line")
        .attr("class", "dk_line")
        .attr("x1", (d, i) => {
          return xScale(X[d]) + xScale.bandwidth() / 2;
        })
        .attr("y1", (d, i) => {
          const dk = data[d].iBs;
          if (dk === 1) {
            return yScale(Y[d][2]) + 12;
          } else {
            return yScale(Y[d][3]) - 12;
          }
        })
        .attr("x2", (d, i) => {
          return xScale(X[d]) + xScale.bandwidth() / 2;
        })
        .attr("y2", (d, i) => {
          const dk = data[d].iBs;
          if (dk === 1) {
            return yScale(Y[d][2]) + 12;
          } else {
            return yScale(Y[d][3]) - 12;
          }
        })
        .attr("stroke-dasharray", "2 2")
        .attr("stroke", (d) => {
          return data[d].iBs == 1 ? "#ff3b30" : "#39b24e";
        }) //F0F0F0
        .attr("stroke-width", 3);
      dkLine
        .transition()
        .delay((d, i) => (d + 1) * delay)
        .duration(200)
        .attr("y2", (d, i) => {
          const dk = data[d].iBs;
          if (dk === 1) {
            return yScale(Y[d][2]) + 25;
          } else {
            return yScale(Y[d][3]) - 25;
          }
        });
      dkGroup
        .selectAll("text")
        .data(dkArr)
        .enter()
        .append("text")
        .attr("class", "dk_text")
        .attr("x", (d) => xScale(X[d]) + xScale.bandwidth() / 2)
        .attr("y", (d, i) => {
          const dk = data[d].iBs;
          if (dk === 1) {
            return yScale(Y[d][2]) + 25;
          } else {
            return yScale(Y[d][3]) - 25;
          }
        })
        .attr("dy", (d, i) => {
          const dk = data[d].iBs;
          if (dk === 1) {
            return "1em";
          } else {
            return "-0.2em";
          }
        })
        .text((d) => {
          const dk = data[d].iBs;
          if (dk === 1) {
            return "D";
          } else {
            return "K";
          }
        })
        .attr("text-anchor", "middle")
        .attr("fill", (d) => (data[d].iBs == 1 ? "#ff3b30" : "#39b24e"))
        .attr("opacity", 0)
        .transition()
        .delay((d, i) => (d + 1) * delay + duration)
        .duration(200)
        .attr("opacity", 1);

      //k线的柱子
      var rects = svg
        .append("g")
        .attr("class", "k_bars_group")
        .selectAll("rect")
        .data(X)
        .enter()
        .append("rect")
        .attr("x", (d, i) => xScale(d))
        .attr("y", (d, i) => yScale(Y[i][0]))
        // return d.fOpen < d.fClose ? yScale(d3.min([d.fClose, d.fHigh])) : yScale(d3.max([d.fClose, d.fLow]));
        .attr("class", (d, i) =>
          Y[i][0] <= Y[i][1] ? "k_bar k_bar_positive" : "k_bar k_bar_negative"
        )
        .attr("width", xScale.bandwidth())
        .attr("height", 0);

      rects
        .transition()
        .delay((d, i) => i * delay)
        .duration(duration)
        .attr("y", (d, i) => yScale(d3.max([Y[i][0], Y[i][1]])))
        .attr("height", (d, i) => {
          const rectHeight = Math.abs(yScale(Y[i][0]) - yScale(Y[i][1]));
          return rectHeight == 0 ? 1 : rectHeight;
        });
        
    // 画阴影区域
    const rectangular=svg 
        .append("g")     
        .selectAll("rect")        
        .data(xTick.slice(0,1))
        .enter() 
        .append("rect")
        .attr("class", "future_gray_rect")
        .attr("x", marginLeft + xTickSpace * Math.ceil(totalLength*3/4-1))
        .attr("y", marginTop)
        .attr("height", height - marginBottom-marginTop)
        .attr("width", xTickSpace * Math.ceil(totalLength*1/4))
        .attr('color', '#E5E5E5')

        
      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped></style>
