<template>
    <div id="bsSignalChart">
        <div class="pages hidden" id="bsSignalPages">
            <page-title id="bsSignalChartText" ref="refPageTitle"></page-title>
            <div ref="refBsSignalHolder" v-if="isBsSignalVisible">
                <k-chart ref="refBsSignalKLine" id="bsSignalKLine" customClass="k_chartbs" />
                <wave-chart ref="refBsSignalWave" id="bsSignalWave" customClass="wave_chartbs" />
                <div class="desc">{{ currentData}}
                    
                </div>
            </div>        
        </div>        
    </div>
    
</template>
<script>
import $ from "jquery";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import KChart from "./kChart.vue";
import waveChart from "./waveChart.vue";


export default {
    data:function(){
        return{
            isBsSignalVisible:false,
            currentData:"",

        }
    },
    components:{
        pageTitle,
        KChart,
        waveChart,
    },
    methods:{
        preview(commandData) {
            setRem(1080);
            this.show(commandData)
        },
        async hide(){
            return new Promise(async (resolve,reject)=>{
                this.$refs.refPageTitle.hide();
                await sleep(200);
                this.isBsSignalVisible = false;
                resolve();
            });
        },
        async show(commandData){
            if(!commandData.isRefresh){
                $("#bsSignalPages").removeClass("hidden");
                this.$refs.refPageTitle.show(commandData);
            }
            this.$nextTick(async ()=>{
                await sleep(300);
                if(["近期多空趋势","行业近期多空趋势"].indexOf(commandData.type) > -1){
                    this.handleBsSignal(commandData);
                }else if (["盘中多空趋势","行业盘中多空趋势"].indexOf(commandData.type) > -1){
                    this.handleBsSignalMin(commandData);

                }
            });
        },
/*==============================================*/
        handleBsSignal(commandData){
            this.currentData=commandData.data.data.dkDesc
            this.isBsSignalVisible = true;
            this.$nextTick(()=>{
                this.renderBsSignal(commandData);
            });
        },
        renderBsSignal(commandData) {
            let width = this.$refs.refBsSignalHolder.clientWidth;
            let kData = commandData.data.data.stockKArray.reverse();
            let wData = commandData.data.data.stockWaveArray.reverse();
            this.$refs.refBsSignalKLine.KLineChart(kData, {
                width: width,
                height: width * 0.38,
                marginTop: 20,
                marginBottom: 50,
                marginLeft: 0,
                marginRight: 0,
                duration: 40,
                delay: 6,
                totalLength:60,
                ease: "easeCubicInOut",
            });
            this.$refs.refBsSignalWave.WaveChart(wData, {
                width: width,
                height: width * 0.2,
                marginTop: 20,
                marginBottom: 50,
                marginLeft: 0,
                marginRight: 0,
                duration: 40,
                delay: 6,
                totalLength:60,
                ease: "easeCubicInOut",
            });
        },

/*==============================================*/
        handleBsSignalMin(commandData){
            // console.log(commandData);
            this.currentData=commandData.data.data.dkDesc
            this.isBsSignalVisible = true;
            this.$nextTick(()=>{
                this.renderBsSignalMin(commandData,commandData.isRefresh);
            });
        },
        renderBsSignalMin(commandData,isRefresh) {
            let width = this.$refs.refBsSignalHolder.clientWidth;
            let kData = commandData.data.data.stockKArray.reverse();
            let wData = commandData.data.data.stockWaveArray.reverse();
            this.$refs.refBsSignalKLine.KLineChart(kData, {
                width: width,
                height: width * 0.38,
                marginTop: 20,
                marginBottom: 50,
                marginLeft: 0,
                marginRight: 0,
                duration: isRefresh ? 0 :40,
                delay: 6,
                totalLength:48,
                ease: "easeCubicInOut",
            });
            this.$refs.refBsSignalWave.WaveChart(wData, {
                width: width,
                height: width * 0.2,
                marginTop: 20,
                marginBottom: 50,
                marginLeft: 0,
                marginRight: 0,
                duration: isRefresh ? 0 :40,
                delay: 6,
                totalLength:48,
                ease: "easeCubicInOut",
            });
        },

    },
    async mounted(){
        window.hide = this.hide;
        window.show = this.preview;
        this.$bus.$on("chartExit",()=>{
            this.hide();
        })
    },

};



</script>
<style lang="less" scoped>
@import "index.less";
</style>