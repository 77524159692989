<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg
        .selectAll(`.kline,.k_bar,.line,.dk_text,.dk_line,.text,.x_tick,.y_tick,.tick_text`)
        .transition()
        .duration(100)
        .style("opacity", "0");
      await sleep(150);
      $("#" + this.id).html("");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    async WaveChart(
      data,
      {
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xPadding = 0.3,
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长        
        totalLength = 48, //默认60根柱子
        lineColors = ["#FF1CA4", "#fcaa05", "#178CEA"], //均线颜色
      } = {}
    ) {
      const X = data.map((d) => d.label);
      const Y = data.map((d) => d.iWave);
      const dataLen = data.length;

      xDomain = X;

      const yMin = -1
      const yMax = 1

      const theSameGap = [yMax - yMin] * 0.11;
      yDomain = [yMin - theSameGap, yMax + theSameGap];

      if (dataLen < totalLength) {
        xRange = [marginLeft, ((width - marginRight - marginLeft) * dataLen) / totalLength + marginLeft];
      }
      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);


      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;


      const yTick = [1, 2, 3];
    //   const xTick = [1, 2, 3, 4, 5, 6, 7];
      
      const xTick = Array.from(Array(totalLength+1).keys()).slice(1);
      const yTickSpace = (height - marginTop - marginBottom) / (yTick.length - 1);
      const xTickSpace = (width - marginLeft - marginRight) / (xTick.length - 1);

      const rowLine = svg
        .append("g")
        .attr("class", "y_tick_group")
        .selectAll("line")
        .data(yTick)
        .enter()
        .append("line")
        .attr("x1", (d, i) => marginLeft)
        .attr("y1", (d, i) => marginTop + yTickSpace * i)
        .attr("x2", (d, i) => marginLeft)
        .attr("y2", (d, i) => marginTop + yTickSpace * i)
        .attr("class", (d, i) => {
            return "y_tick"+i
        })
        .attr("stroke", "#F0F0F0")
        .attr("stroke-width", 2)
        .attr("stroke-dasharray", 5)
        .attr("opacity", 1)
        .transition()
        .ease(d3.easeCubicOut)
        .delay((d, i) => i * 50)
        .duration(300)
        .attr("x2", (d, i) => width - marginLeft- marginRight);

      const columnLine = svg
        .append("g")
        .attr("class", "x_tick_group")
        .selectAll("line")
        .data(xTick)
        .enter()
        .append("line")
        .attr("x1", (d, i) => marginLeft + xTickSpace * i)
        .attr("y1", (d, i) => marginTop)
        .attr("x2", (d, i) => marginLeft + xTickSpace * i)
        .attr("y2", (d, i) => marginTop)
        .attr("class", (d, i) => {
            return "x_tick"+i
        })
        .attr("stroke", "#F0F0F0") //F0F0F0
        .attr("stroke-width", 2)
        .attr("stroke-dasharray", 5)
        .attr("opacity",  (d, i) => {
            if(i == totalLength*3/4-1){
                return 1
            }else {
                return 0
            }
            })
        .transition()
        .ease(d3.easeCubicOut)
        .delay((d, i) => i * 5)
        .duration(300)
        .attr("y2", (d, i) => height - marginBottom);
    
    //   k线的柱子
      var rects = svg
        .append("g")
        .attr("class", "k_bars_group")
        .selectAll("rect")
        .data(X)
        .enter()
        .append("rect")
        .attr("x", (d, i) => xScale(d))
        .attr("y", (d, i) => {
          console.log(Y[i],yScale(Y[i]),'坐标',yScale(-1),yScale(1));
              yScale(0)
          })
        .attr("class", (d, i) =>
          Y[i] >= 0  ? "k_bar k_bar_positive" : "k_bar k_bar_negative"
        )
        .attr("width", xScale.bandwidth())
        .attr("height", 0);

      rects
        .transition()
        .delay((d, i) => i * delay)
        .duration(duration)
        .attr("y", (d, i) => yScale(0))
        .attr("height", (d, i) => {
            let rectHeight
            if(Y[i]==0){
                rectHeight=0
            }else if(Y[i]<0){
                rectHeight = yScale(Y[i])-yScale(0);
                rectHeight = Math.abs(rectHeight)
                console.log(Y[i],yScale(Y[i]),rectHeight,'海波信号的高度');
            }else if(Y[i]>0){
                rectHeight = yScale(Y[i]);
                rectHeight = rectHeight
            }
          return rectHeight == 0 ? 0 : rectHeight;
        });


        
    // 画阴影区域
    const rectangular=svg 
        .append("g")     
        .selectAll("rect")        
        .data(xTick.slice(0,1))
        .enter() 
        .append("rect")
        .attr("class", "future_gray_rect")
        .attr("x", marginLeft + xTickSpace * (totalLength*3/4-1))
        .attr("y", marginTop)
        .attr("height", height - marginBottom-marginTop)
        .attr("width", xTickSpace * totalLength*1/4)
        .style('color', '#E5E5E5')

        
      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped></style>
